import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
// import styles from './heroHeader.module.scss'

export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            home {
              title
              description
            }
          }
        }
      }
    `}
    render={data => (
      <div className="hero-header">
        <h1 className="headline">{data.site.siteMetadata.home.title}</h1>
        <div 
          className="primary-content" 
          dangerouslySetInnerHTML={{ __html: data.site.siteMetadata.home.description}}
        />
        <Link to='/clinics' className="button -primary">Find a clinic &rarr;</Link>
        <Link to='/contact' className="button">Get in touch &rarr;</Link>
      </div>
    )}
  />
)