import React from "react"
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import HeroHeader from "../components/heroHeader"
import {Link} from 'gatsby'

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges }
  },
}) => {
  
  return (
    <Layout>
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <HeroHeader/>

      <h2>Our approach to Hand Therapy</h2>
      <p>We are dedicated to providing excellent hand, wrist and upper limb therapy.</p>
      <p>Our hand therapy team in three Sydney practices has helped business people, professional sportspeople, mothers, seniors, musicians, sports &amp; fitness enthusiasts (eg. gym, golf, tennis), tradespeople and children reduce pain and return them back to their usual activities.</p>
      <p>We offer a holistic approach to treatment creating an effective partnership between the surgeon / doctor and hand therapist (physiotherapist or occupational therapist) that is essential for optimal recovery.</p>

      <h2>Find a clinic:</h2>
      <ul>
        <li><Link to="/clinics#sydney-cbd">Sydney CBD &rarr;</Link></li>
        <li><Link to="/clinics#hornsby">Hornsby &rarr;</Link></li>
        <li><Link to="/clinics#potts-point">Pott's Point &rarr;</Link></li>
      </ul>
      
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(filter: {
      frontmatter: {template: {eq: "BlogPost"}}
    }) {
      totalCount
      edges {
        node {
          frontmatter {
            title
          }
        }
      }
    }
  }
`
